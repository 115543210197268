import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { stringOrDate } from 'react-big-calendar';
import env from 'config/env';
import { DayOfWeek, EnrollmentStatus, PackageType } from 'generated/graphql';
import { useAdminContext } from 'contexts/AdminContext/AdminContext';
import useQueryEnrollmentsOnSchedule from 'hooks/useQueryEnrollmentsOnSchedule/useQueryEnrollmentsOnSchedule';
import useQueryScheduleAvailability from 'hooks/queries/useQueryScheduleAvailability/useQueryScheduleAvailability';
import EnrollmentList from 'components/molecules/EnrollmentList/EnrollmentList';
import { DateTime, WeekdayNumbers } from 'luxon';
import { EnrollmentFromQuery } from 'types';
import useMutationScheduleEnrollment from 'hooks/useMutationScheduleEnrollment/useMutationScheduleEnrollment';
import InstructorSelectField from 'components/organisms/InstructorSelectField/InstructorSelectField';
import Stack from '@mui/material/Stack';
import filterEnrollments from 'utils/SemesterCalendar/filterEnrollments';
import { SchedulerFilters } from 'pages/Admin/Scheduler/types';
import { styled } from '@mui/material/styles';
import { isEnrollmentEvent } from 'utils/SemesterCalendar/isEnrollmentEvent';
import Button from '@mui/material/Button';
import EnrollmentConfirmationsDialog from 'components/organisms/EnrollmentConfirmationsDialog/EnrollmentConfirmationsDialog';
import HeaderPortal from 'components/organisms/HeaderPortal/HeaderPortal';
import SchedulerHeader from 'pages/Admin/NewScheduler/components/SchedulerHeader/SchedulerHeader';
import { SchedulingConsiderationsContextProvider } from 'contexts/SchedulingConsiderationsContext/SchedulingConsiderationsContext';
import { ScheduleSolverContextProvider } from 'contexts/ScheduleSolverContext/ScheduleSolverContext';
import NewSemesterCalendar, { ScheduleEvent } from 'components/organisms/NewSemesterCalendar/NewSemesterCalendar';
import EnrollmentDrawer from 'pages/Admin/NewScheduler/components/EnrollmentDrawer/EnrollmentDrawer';
import useQueryGetSkills from 'hooks/queries/useQueryGetSkills/useQueryGetSkills';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useLocalStorageState from 'hooks/useLocalStorageState/useLocalStorageState';
import EnrollmentSearch from 'components/organisms/NewSemesterCalendar/components/EnrollmentSearch/EnrollmentSearch';
import { SchedulerSlotInfo } from 'components/organisms/NewSemesterCalendar/components/SchedulingCalendar/SchedulingCalendar';
import Snackbar from '@mui/material/Snackbar';
import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';

const dayOfWeekLuxonIndexMap: Record<WeekdayNumbers, DayOfWeek> = {
  1: DayOfWeek.Monday,
  2: DayOfWeek.Tuesday,
  3: DayOfWeek.Wednesday,
  4: DayOfWeek.Thursday,
  5: DayOfWeek.Friday,
  6: DayOfWeek.Saturday,
  7: DayOfWeek.Sunday,
};

// NOTE: filtering out Inactive enrollment by default
const enrollmentStatus = Object.values(EnrollmentStatus).filter((status) => status !== EnrollmentStatus.Inactive);

function getDayOfWeekForDateTime(dt: DateTime): DayOfWeek {
  return dayOfWeekLuxonIndexMap[dt.weekday as WeekdayNumbers];
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const NewScheduler = () => {
  const {
    selectedSchedule,
    selectedInstructors,
    setSelectedInstructors,
    currentView,
    daysOfWeek,
    selectedEnrollment,
    setSelectedEnrollment,
  } = useAdminContext();
  const [draggedEvent, setDraggedEvent] = useState<ScheduleEvent | null>();
  const [viewByInstructor] = useState<boolean>(true);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [enrollmentInDrawer, setEnrollmentInDrawer] = useLocalStorageState<EnrollmentFromQuery | undefined>(
    'enrollmentDrawer',
    undefined
  );
  const [enrollmentFilters, setEnrollmentFilters] = useLocalStorageState<SchedulerFilters>('enrollmentFilters', {
    scheduleStatus: [],
  });
  const [hopperEnrollmentFilters, setHopperEnrollmentFilters] = useLocalStorageState<SchedulerFilters>(
    'hopperEnrollmentFilters',
    {
      scheduleStatus: [],
      search: '',
    }
  );

  const { data: availabilityData, loading } = useQueryScheduleAvailability(selectedSchedule?.id);
  const { data: enrollmentData, subscribeToEnrollmentUpdates } = useQueryEnrollmentsOnSchedule({
    schedulingStatus: enrollmentStatus,
  });
  const instrumentsResult = useQueryGetSkills();
  const instruments = useMemo(() => instrumentsResult.data?.getSkills || [], [instrumentsResult.data?.getSkills]);

  const [scheduleEnrollment] = useMutationScheduleEnrollment();

  // Subscribe to enrollment updates
  useEffect(() => {
    subscribeToEnrollmentUpdates();
  }, [subscribeToEnrollmentUpdates]);

  const filters = useMemo<SchedulerFilters>(
    () => ({ instructors: selectedInstructors, ...enrollmentFilters }),
    [selectedInstructors, enrollmentFilters]
  );

  const unscheduled = useMemo(
    () =>
      filterEnrollments(
        enrollmentData?.enrollments.filter(
          (v) => v.schedulingStatus === EnrollmentStatus.New && v.package?.packageType !== PackageType.PublicGroupLesson
        ) || [],
        hopperEnrollmentFilters
      ),
    [enrollmentData?.enrollments, hopperEnrollmentFilters]
  );
  const scheduled = useMemo(
    () =>
      filterEnrollments(
        enrollmentData?.enrollments.filter((v) => v.schedulingStatus !== EnrollmentStatus.New) || [],
        filters,
        true
      ),
    [filters, enrollmentData?.enrollments]
  );
  const readyForConfirmation = useMemo(
    () =>
      filterEnrollments(
        enrollmentData?.enrollments || [],
        { scheduleStatus: [EnrollmentStatus.ReadyForConfirmation] },
        true
      ),
    [enrollmentData?.enrollments]
  );

  const handleEnrollmentClick = useCallback(
    (enrollment: EnrollmentFromQuery) => {
      setEnrollmentInDrawer(enrollment);
      setSelectedEnrollment(undefined);
    },
    [setEnrollmentInDrawer, setSelectedEnrollment]
  );

  const handleScheduleEnrollmentClick = useCallback(
    (enrollment: EnrollmentFromQuery) => {
      setSelectedEnrollment((prevState) => (prevState?.id !== enrollment?.id ? enrollment : undefined));
    },
    [setSelectedEnrollment]
  );

  const handleDragStart = useCallback((enrollment: EnrollmentFromQuery) => {
    setDraggedEvent(enrollment);
  }, []);

  const handleSelectEvent = useCallback(
    (enrollment: EnrollmentFromQuery) => {
      setEnrollmentInDrawer(enrollment);
    },
    [setEnrollmentInDrawer]
  );

  const handleMoveEvent = useCallback(
    async ({
      event,
      start,
      isAllDay: droppedOnAllDaySlot = false,
      resourceId,
    }: {
      event: ScheduleEvent;
      start: stringOrDate;
      end: stringOrDate;
      isAllDay: boolean;
      resourceId?: number | string;
    }) => {
      const allDay = false;
      if (!allDay && droppedOnAllDaySlot) {
        window.alert('Cannot drop on all day slot');
        return;
      }

      if (isEnrollmentEvent(event)) {
        const dt = typeof start === 'string' ? DateTime.fromISO(start) : DateTime.fromJSDate(start);
        const { enrollment } = event;

        const targetInstructorId =
          typeof resourceId === 'number'
            ? resourceId
            : enrollment.scheduledInstructorId || enrollment.preferredInstructorId;

        if (targetInstructorId) {
          await scheduleEnrollment({
            variables: {
              input: {
                id: enrollment.id,
                dayOfWeek: getDayOfWeekForDateTime(dt),
                instructorId: targetInstructorId,
                startTime: dt.toFormat('TT'),
              },
            },
          });

          setSelectedEnrollment(undefined);
        } else {
          window.alert('Scheduling enrollments without instructor not yet implemented.');
        }
      }
    },
    [scheduleEnrollment, setSelectedEnrollment]
  );

  const handleSelectSlot = useCallback(
    async ({ dayOfWeek, startTime, resourceId, scheduledLocationId }: SchedulerSlotInfo) => {
      if (selectedEnrollment) {
        const targetInstructorId =
          typeof resourceId === 'number'
            ? resourceId
            : selectedEnrollment.scheduledInstructorId || selectedEnrollment.preferredInstructorId;

        if (targetInstructorId) {
          await scheduleEnrollment({
            variables: {
              input: {
                id: selectedEnrollment.id,
                dayOfWeek,
                instructorId: targetInstructorId,
                startTime,
                scheduledLocationId,
              },
            },
          });

          setSelectedEnrollment(undefined);
        } else {
          window.alert('Scheduling enrollments without instructor not yet implemented.');
        }
      }
    },
    [scheduleEnrollment, selectedEnrollment, setSelectedEnrollment]
  );

  const drawerEnrollment = useMemo(
    () => (enrollmentInDrawer ? enrollmentData?.enrollments.find((x) => x.id === enrollmentInDrawer.id) : undefined),
    [enrollmentData?.enrollments, enrollmentInDrawer]
  );

  return (
    <ScheduleSolverContextProvider>
      <SchedulingConsiderationsContextProvider scheduleId={selectedSchedule?.id}>
        <HeaderPortal>
          <SchedulerHeader />
        </HeaderPortal>
        <Grid container columnSpacing={7.25}>
          <Grid item sm={4} md={3} lg={2}>
            <Box sx={{ height: `calc(100vh - ${env.sizing.admin.vhOffset})` }} flexDirection='column' display='flex'>
              <Box flexGrow={0} mb={1} sx={{ minWidth: 200 }}>
                <Stack spacing={2}>
                  <EnrollmentSearch
                    enrollments={enrollmentData?.enrollments || []}
                    selectedEnrollment={enrollmentInDrawer}
                    onChange={(newValue) => setEnrollmentInDrawer(newValue || undefined)}
                  />
                  <InstructorSelectField
                    onChange={setSelectedInstructors}
                    value={selectedInstructors}
                    label='Filter by teacher(s)'
                    placeholder='Teachers'
                    skillIds={enrollmentFilters.skillIds}
                  />
                  <Autocomplete
                    id='instrumentFilter'
                    multiple
                    options={instruments}
                    value={instruments.filter((x) => enrollmentFilters.skillIds?.includes(x.id))}
                    disableCloseOnSelect
                    getOptionLabel={(instrument) => instrument.skillName}
                    onChange={(event, value) => {
                      setEnrollmentFilters({
                        ...enrollmentFilters,
                        instruments: value.map((x) => x.skillName),
                        skillIds: value.map((x) => x.id),
                      });
                    }}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.skillName}
                      </li>
                    )}
                    renderInput={(params) => <TextField {...params} label='Instrument(s)' placeholder='' />}
                  />
                  {/* Hiding the Enrollment status for now: https://caxyinteractive.atlassian.net/browse/LMA-410 */}
                  {/*<Autocomplete*/}
                  {/*  id='enrollmentStatusFilter'*/}
                  {/*  multiple*/}
                  {/*  options={enrollmentStatus}*/}
                  {/*  disableCloseOnSelect*/}
                  {/*  getOptionLabel={(option) => option}*/}
                  {/*  value={enrollmentFilters.scheduleStatus}*/}
                  {/*  onChange={(event, value) => {*/}
                  {/*    setEnrollmentFilters({ ...enrollmentFilters, scheduleStatus: value });*/}
                  {/*  }}*/}
                  {/*  renderOption={(props, option, { selected }) => (*/}
                  {/*    <li {...props}>*/}
                  {/*      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />*/}
                  {/*      {option}*/}
                  {/*    </li>*/}
                  {/*  )}*/}
                  {/*  renderInput={(params) => <TextField {...params} label='Enrollment Status' placeholder='' />}*/}
                  {/*/>*/}
                  {readyForConfirmation.length > 0 && (
                    <div>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        onClick={() => setConfirmationDialogOpen(true)}
                      >
                        Send Confirmations ({readyForConfirmation.length} ready)
                      </Button>
                      <EnrollmentConfirmationsDialog
                        enrollments={readyForConfirmation}
                        open={confirmationDialogOpen}
                        onClose={() => setConfirmationDialogOpen(false)}
                      />
                    </div>
                  )}
                </Stack>
              </Box>
              <StyledBox flexGrow={0}>
                <TextField
                  id='hopperSearch'
                  label={`Search classes to schedule (${unscheduled.length})`}
                  variant='standard'
                  size='small'
                  value={hopperEnrollmentFilters.search || ''}
                  onChange={(e) => setHopperEnrollmentFilters((prev) => ({ ...prev, search: e.target.value }))}
                />
              </StyledBox>
              <Box flexGrow={1} py={1} sx={{ overflowY: 'auto' }}>
                {unscheduled && (
                  <EnrollmentList
                    enrollments={unscheduled}
                    selectedId={selectedEnrollment?.id}
                    onClick={handleEnrollmentClick}
                    onScheduleClick={handleScheduleEnrollmentClick}
                    onDragStart={handleDragStart}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item sm={8} md={9} lg={10}>
            {availabilityData ? (
              <NewSemesterCalendar
                availability={availabilityData?.scheduleAvailability}
                enrollments={scheduled}
                selectedFilters={filters}
                selectedEnrollment={selectedEnrollment}
                selectedInstructors={selectedInstructors}
                onEventDrop={handleMoveEvent}
                draggedEvent={draggedEvent || undefined}
                onSelectSlot={selectedEnrollment ? handleSelectSlot : undefined}
                byResource={viewByInstructor}
                selected={enrollmentInDrawer}
                onSelectEvent={handleSelectEvent}
                view={currentView}
                daysOfWeek={daysOfWeek}
                selectable
              />
            ) : (
              <Box margin={3} display='flex' justifyContent='center'>
                {loading ? <LoadingIndicator /> : 'There are no appointments that match that Criteria'}
              </Box>
            )}
          </Grid>
        </Grid>
        {drawerEnrollment && (
          <EnrollmentDrawer
            drawerOpen={true}
            switchEnrollment={(enrollment) => setEnrollmentInDrawer(enrollment)}
            onClose={() => setEnrollmentInDrawer(undefined)}
            enrollment={drawerEnrollment}
          />
        )}
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          open={!!selectedEnrollment}
          onClose={(e, reason) => reason !== 'clickaway' && setSelectedEnrollment(undefined)}
          message='Moving selected enrollment...'
          action={
            <Button color='secondary' size='small' onClick={() => setSelectedEnrollment(undefined)}>
              CANCEL
            </Button>
          }
        />
      </SchedulingConsiderationsContextProvider>
    </ScheduleSolverContextProvider>
  );
};

export default NewScheduler;
