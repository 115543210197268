import React, { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import { CalendarProps as BaseCalendarProps } from 'react-big-calendar';
import { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import { DateTime, Zone } from 'luxon';
import Box, { BoxProps } from '@mui/material/Box';
import { getDateTime } from 'utils/dates';
import { DayOfWeek } from 'generated/graphql';
import {
  BodyCell,
  BodyCol,
  BodyCols,
  BodyContent,
  GutterCell,
  GutterCol,
  HeaderCell,
  HeaderCols,
  HeaderContent,
  HeaderGutterCell,
  HeaderTitle,
  FooterCol,
  StyledHeader,
} from './SchedulingCalendar.styled';
import { EnrollmentFromQuery } from 'types';
import EnrollmentEventCard from 'components/organisms/NewSemesterCalendar/components/EnrollmentEventCard/EnrollmentEventCard';
import { getEventsByDay } from 'components/organisms/NewSemesterCalendar/utils/getEventsByDay';
import { sortEnrollments } from 'components/organisms/NewSemesterCalendar/utils/sortEnrollments';
import { InstructorProfileData } from 'hooks/queries/useQueryInstructorProfiles/useQueryInstructorProfiles';
import { NO_PREFERENCE_VALUE } from 'components/organisms/InstructorSelectField/InstructorSelectField';
import colorMapper from 'utils/SemesterCalendar/colorMapper';
import { Button, Dialog, DialogContent, DialogTitle, MenuItem, Select, Typography } from '@mui/material';
import { allDaysOfWeek } from 'utils/availability/availabilityUtils';
import { useSchedulingConsiderationsContext } from 'contexts/SchedulingConsiderationsContext/SchedulingConsiderationsContext';

const DEFAULT_CALENDAR_MIN = DateTime.fromFormat('08:00:00', 'TT').toJSDate();
const DEFAULT_CALENDAR_MAX = DateTime.fromFormat('22:00:00', 'TT').toJSDate();

export type SCHEDULER_VIEW = 'week' | 'work_week' | 'day' | 'custom_days';

export type SchedulerSlotPropGetter = (
  dayOfWeek: DayOfWeek,
  time: string,
  resourceId?: number | string
) => Partial<BoxProps>;

export interface Event {
  id: string | number;
  allDay?: boolean | undefined;
  title?: React.ReactNode | undefined;
  start?: Date;
  end?: Date;
  resource?: unknown;
}

export interface SchedulerSlotInfo {
  dayOfWeek: DayOfWeek;
  startTime: string;
  action: 'select' | 'click' | 'doubleClick';
  resourceId?: number | string | undefined;
  scheduledLocationId?: number;
}

type TimeSlotGroup = {
  groupStart: DateTime;
  innerSlots: DateTime[];
};

function getTimeSlots(step: number, timeslots: number, min: Date, max: Date): TimeSlotGroup[] {
  let current = getDateTime(min);
  const end = getDateTime(max);
  const slots: TimeSlotGroup[] = [];

  while (current < end) {
    const groupStart = current;
    const innerSlots: DateTime[] = [];
    for (let i = 0; i < timeslots; i++) {
      innerSlots.push(current);
      current = current.plus({ minute: step });
    }

    slots.push({ groupStart, innerSlots });
  }

  return slots;
}

function filterEnrollments(enrollments: EnrollmentFromQuery[], instructor?: InstructorProfileData) {
  if (!instructor) {
    return enrollments;
  }

  return enrollments.filter((enrollment) => enrollment.scheduledInstructorId === instructor.profileId);
}

export interface SchedulingCalendarProps<
  TEvent extends EnrollmentFromQuery = EnrollmentFromQuery,
  TResource extends object = object
> extends Partial<
      Omit<BaseCalendarProps<TEvent, TResource>, 'defaultView' | 'view' | 'slotPropGetter' | 'onSelectSlot'>
    >,
    Partial<Omit<withDragAndDropProps<TEvent, TResource>, 'defaultView' | 'view'>> {
  timezone?: Zone | string;
  instructors: InstructorProfileData[];
  daysOfWeek?: DayOfWeek[];
  view?: SCHEDULER_VIEW;
  defaultView?: SCHEDULER_VIEW;
  slotPropGetter?: SchedulerSlotPropGetter;
  onSelectSlot?: (slotInfo: SchedulerSlotInfo) => void;
}

type Props<
  TEvent extends EnrollmentFromQuery = EnrollmentFromQuery,
  TResource extends object = object
> = SchedulingCalendarProps<TEvent, TResource>;

const MultipleSchedulingCalendar = <
  TEvent extends EnrollmentFromQuery = EnrollmentFromQuery,
  TResource extends object = object
>({
  events,
  view,
  min = DEFAULT_CALENDAR_MIN,
  max = DEFAULT_CALENDAR_MAX,
  timeslots = 2,
  step = 15,
  defaultView = 'week',
  selected,
  onSelectEvent,
  instructors,
  daysOfWeek,
  slotPropGetter,
  onSelectSlot,
  ...props
}: Props<TEvent, TResource>) => {
  const { scheduleId } = useSchedulingConsiderationsContext();
  const [open, setOpen] = useState(false);
  const slotGroups = useMemo(() => getTimeSlots(step, timeslots, min, max), [max, min, step, timeslots]);
  const currentView = view ?? defaultView;
  const currentDays = useMemo(
    () => (currentView === 'week' ? allDaysOfWeek : daysOfWeek?.length ? daysOfWeek : [DayOfWeek.Monday]),
    [currentView, daysOfWeek]
  );
  const dayCount = currentDays.length;
  const sortedEvents = useMemo(
    () => instructors.map((instructor) => sortEnrollments(filterEnrollments(events || [], instructor))),
    [events, instructors]
  );

  const eventsByDay = useMemo(
    () =>
      sortedEvents.map((sortedEvent) => getEventsByDay<TEvent>(currentDays, sortedEvent, min, max, timeslots, step)),
    [currentDays, max, min, sortedEvents, step, timeslots]
  );

  const calender = useRef<HTMLDivElement>(null);
  const instructorRefs = useRef<RefObject<HTMLDivElement>[]>(instructors.map(() => React.createRef()));

  const selectInstructor = useCallback(
    (index: number) => {
      if (calender.current) {
        const target = calender.current;
        const selectedInstructor = instructorRefs.current[0];
        if (selectedInstructor.current) {
          target.scrollLeft = selectedInstructor.current?.getBoundingClientRect().width * index;
        }
      }
    },
    [calender, instructorRefs]
  );

  return (
    <Box display='flex' overflow='auto' width='100%' height='100%' ref={calender}>
      {instructors.length > 0 &&
        instructors.map(({ user, profileId, calendarColor }, instructorIdx) => {
          const headerText = user ? `${user?.firstName} ${user?.lastName}` : profileId.toString();
          const colorId = `instructor_${profileId.toString() || NO_PREFERENCE_VALUE}`;

          return (
            <Box
              key={`${headerText}-multiple-scheduling-calender`}
              sx={{ overflow: 'none' }}
              style={props.style}
              ref={instructorRefs.current[instructorIdx]}
            >
              <StyledHeader>
                <HeaderGutterCell sx={{ position: 'unset' }}>
                  <Button fullWidth sx={{ height: '100%' }} onClick={() => setOpen(true)}>
                    Find
                  </Button>
                  <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Find Instructor</DialogTitle>
                    <DialogContent sx={{ minWidth: '20rem' }}>
                      <Select
                        fullWidth
                        defaultValue={-1}
                        onChange={({ target }) => {
                          selectInstructor(Number(target.value) ?? 0);
                          setOpen(false);
                        }}
                      >
                        <MenuItem disabled value={-1}>
                          <em>Go to Instructor</em>
                        </MenuItem>
                        {instructors.map(({ user, profileId }, idx) => (
                          <MenuItem key={`${idx}-instuctor-hot-key`} value={idx}>
                            {user ? `${user?.firstName} ${user?.lastName}` : profileId.toString()}
                          </MenuItem>
                        ))}
                      </Select>
                    </DialogContent>
                  </Dialog>
                </HeaderGutterCell>
                <HeaderContent sx={{ width: 'fit-content' }}>
                  <HeaderTitle $bgColor={calendarColor || colorMapper.pickColor(colorId)}>{headerText}</HeaderTitle>
                  <HeaderCols>
                    {currentDays.map((dayOfWeek, columnIndex) => (
                      <HeaderCell
                        key={dayOfWeek}
                        sx={{
                          width: `${100 / dayCount}%`,
                          borderLeft: columnIndex === 0 ? 'none' : undefined,
                        }}
                      >
                        {`${dayOfWeek[0].toUpperCase()}${dayOfWeek.slice(1).toLowerCase()}`}
                      </HeaderCell>
                    ))}
                  </HeaderCols>
                </HeaderContent>
              </StyledHeader>
              <BodyContent>
                <GutterCol sx={{ position: 'unset' }}>
                  {slotGroups.map((slotGroup) => (
                    <GutterCell key={slotGroup.groupStart.toISO()}>{slotGroup.groupStart.toFormat('t')}</GutterCell>
                  ))}
                  <GutterCell>Location</GutterCell>
                </GutterCol>
                <BodyCols>
                  {currentDays.map((dayOfWeek, columnIndex) => {
                    const dayEvents = eventsByDay[instructorIdx][dayOfWeek] || [];
                    const availability =
                      instructors[instructorIdx]?.scheduleAvailability?.find(
                        ({ scheduleId: _scheduleId }) => _scheduleId === scheduleId
                      )?.availabilityItems ?? instructors[instructorIdx]?.defaultAvailability?.availabilityItems;
                    const availabilityItems =
                      availability?.filter(({ dayOfWeek: _dayOfWeek }) => _dayOfWeek === dayOfWeek) || [];
                    return (
                      <BodyCol key={dayOfWeek} sx={{ width: `${100 / dayCount}%` }}>
                        {slotGroups.map((slotGroup) => (
                          <BodyCell
                            key={slotGroup.groupStart.toISO()}
                            sx={{ borderLeft: columnIndex === 0 ? 'none' : undefined }}
                          >
                            {slotGroup.innerSlots.map((innerSlot) => {
                              const slotProps = slotPropGetter?.(
                                dayOfWeek,
                                innerSlot.toFormat('TT'),
                                instructors[instructorIdx].profileId
                              );
                              return (
                                <Box
                                  key={innerSlot.toISO()}
                                  {...slotProps}
                                  sx={{
                                    ...slotProps?.sx,
                                    flex: '1 0',
                                    ...(onSelectSlot
                                      ? {
                                          zIndex: 65,
                                          '&:hover': {
                                            backgroundColor: '#BBBBBB',
                                          },
                                        }
                                      : undefined),
                                  }}
                                  onClick={
                                    onSelectSlot
                                      ? () =>
                                          onSelectSlot({
                                            action: 'click',
                                            dayOfWeek,
                                            startTime: innerSlot.toFormat('TT'),
                                            resourceId: instructors[instructorIdx].profileId,
                                            scheduledLocationId: availabilityItems[0]?.locations?.[0]?.id,
                                          })
                                      : undefined
                                  }
                                ></Box>
                              );
                            })}
                          </BodyCell>
                        ))}
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            height: 'calc(100% - 4.1875rem)',
                          }}
                        >
                          {dayEvents.map(({ enrollment: event, styles }) => {
                            if (!event.startTime || !event.endTime) {
                              return null;
                            }
                            return (
                              <Box
                                key={event.id}
                                sx={{
                                  position: 'absolute',
                                  left: 0,
                                  right: 0,
                                  cursor: 'pointer',
                                  ...styles,
                                  zIndex: selected && selected.id === event.id ? 40 : styles?.zIndex,
                                  '&:hover': {
                                    zIndex: 60,
                                  },
                                }}
                              >
                                <EnrollmentEventCard enrollment={event} onClick={onSelectEvent} />
                              </Box>
                            );
                          })}
                        </Box>
                        <FooterCol>
                          <HeaderCell sx={{ borderLeft: columnIndex === 0 ? 'none' : undefined, height: '4.1875rem' }}>
                            <Box>
                              {availabilityItems.length > 0
                                ? availabilityItems.map(({ id, locations, startTime, endTime }) => (
                                    <Box key={`availabilityItem-${id}`} textAlign='center'>
                                      {locations
                                        ? locations?.map(({ locationIdentifier, site }) => (
                                            <Box key={`location-${locationIdentifier}-$${site?.siteIdentifier}`}>
                                              {locationIdentifier}
                                              {site?.siteIdentifier ? ` - ${site?.siteIdentifier}` : ''}
                                            </Box>
                                          ))
                                        : 'No locations selected'}
                                      {availabilityItems?.length > 1 && (
                                        <Typography fontSize={8}>
                                          {startTime?.split(':').slice(0, -1).join(':')}-
                                          {endTime?.split(':').slice(0, -1).join(':')}
                                        </Typography>
                                      )}
                                    </Box>
                                  ))
                                : 'Not Available'}
                            </Box>
                          </HeaderCell>
                        </FooterCol>
                      </BodyCol>
                    );
                  })}
                </BodyCols>
              </BodyContent>
            </Box>
          );
        })}
    </Box>
  );
};

export default MultipleSchedulingCalendar;
